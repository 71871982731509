<template>
  <!-- 帮助中心 -->
  <div>
    <!-- 帮助中心 -->
    <div class="flex">
      <div>
        <span>标题</span>
        <el-input size="medium" v-model="form" placeholder="标题"></el-input>
        <div>
          <el-button size="medium" @click="resetForm">重置</el-button>
          <el-button size="medium" type="primary">查询</el-button>
        </div>
      </div>
      <el-button size="medium" type="danger" @click="newNews">新增</el-button>
    </div>

    <!-- 中间表格区域 -->
    <el-table :data="data" border>
      <el-table-column label="序号" prop="id" width="140"></el-table-column>
      <el-table-column label="标题" prop="title" width="240"></el-table-column>
      <el-table-column label="频道" prop="content" width="140"></el-table-column>
      <el-table-column label="发布时间" prop="createTime" width="160"></el-table-column>
      <el-table-column label="状态" prop="status" width="100"></el-table-column>
      <el-table-column label="操作人" prop="adminName" width="100"></el-table-column>
      <el-table-column label="推荐" prop="recommend" width="100"></el-table-column>
      <el-table-column label="推荐时排序" prop="remarkName" width="160"></el-table-column>
      <el-table-column label="操作" prop="time" width="">
        <template slot-scope="scope">
          <div class="operation">
            <div @click="onAdminSee(scope.row.id, scope.$index)">查看</div>
            <div>修改</div>
            <div @click="onAdmin(scope.row.id, scope.$index)">删除</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[1, 2, 3, 4, 5]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      form: '',
      pageSize: 10,
      time: '',
      totalItems: 0,
      currentPage4: 4
    }
  },
  created() {
    this.goHelp()
  },
  methods: {
    onAdminSee(id, index) {
      this.$router.push(`adminsee?id=${id}&type=${index}`)
    },
    // 删除
    onAdmin(id, index) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.axios
            .delete(
              `/admin/help/delHelp
?id=${id}`,
              {}
            )
            .then(res => {
              if (res.data.code == 200) {
                // 删除元素
                this.data.splice(index, 1)
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              } else {
                this.$message({
                  type: 'errer',
                  message: '删除失败!'
                })
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    goHelp() {
      this.axios
        .get('/admin/help/showHelp', {
          currentPage: 1,
          pageSize: 10
        })
        .then(res => {
          console.log(res)
          this.data = res.data.data.records
        })
        .catch(err => {})
    },
    //   重置
    resetForm() {
      this.form = ''
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },

    // 跳转帮助中心新增
    newNews() {
      // alert("新增帮助中心配置");
      this.$router.push('addadministration')
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    span {
      width: 80px;
    }
    div {
      margin: 0 10px;
    }
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>
